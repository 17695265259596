import React from 'react';
import './About.css';

const AboutPage = () => {
    return (
        <section className="about-page">
            <div className="about-container">
                <div className="about-image">
                    <img src="/aboutuspage.jpg" alt="About us" />
                    <div className="decorative-circle"></div> {/* Decorative shape */}
                </div>
                <div className="about-content">
                    <h1>About Us</h1>
                    <p>
                        At <strong>Target BluPrint</strong>, we specialize in creating powerful, user-friendly websites for startups and small businesses 
                        looking to establish or expand their online presence. We understand the unique challenges that come with launching 
                        and growing a business, which is why we’re committed to delivering tailored web solutions 
                        that not only reflect your brand’s identity but also drive results.
                    </p>
                    <p>
                        <strong>Target</strong> was founded with a passion for design, technology, and innovation. Our mission is to 
                        help startups and small businesses succeed online by providing accessible, scalable solutions that 
                        grow with your business.
                    </p>
                    <p>
                        From custom web development and branding solutions and ongoing support, we’re 
                        here to help you reach your full potential online.
                    </p>
                </div>
            </div>
            <div className="about-footer">
                <a
                    href="https://www.linkedin.com/in/target-bluprint-09310a336/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                    href="https://www.facebook.com/profile.php?id=61567788455187"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-facebook-f"></i>
                </a>
                <a
                    href="https://www.instagram.com/targetbluprint/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-instagram"></i>
                </a>
            </div>
        </section>
    );
};

export default AboutPage;


