// src/components/Services.js
import React from 'react';
import './Services.css'; // Import the CSS for the services section

const Services = () => {
  return (
    <div className="services-section">
      <h2>Services</h2>
      <p className="services-description">
        Explore our range of services designed to help small businesses establish and grow their online presence. From basic web design to comprehensive custom development, we have a solution for your needs.
      </p>

      <div className="services-cards">
        <div className="service-card slide-in-bottom">
          <h3>Startup Web Design</h3>
          <p>Starting from $500</p>
          <ul>
              <li>Custom 1-3 page website</li>
              <li>Mobile-responsive design</li>
              <li>Navigation Bar</li>
          </ul>
        </div>
        <div className="service-card slide-in-bottom">
          <h3>Website Hosting</h3>
          <p>$50 / Monthly</p>
          <ul>
              <li>Custom 1-5 page website</li>
              <li>Mobile-responsive design</li>
          </ul>
        </div>
        <div className="service-card slide-in-bottom">
          <h3>Custom Web Development</h3>
          <p>Starting from $800</p>
          <ul>
              <li>Custom 1-5 page website</li>
              <li>Mobile-responsive design</li>
              <li>Navigation Bar</li>
              <li>Contact Form</li>
              <li>Bookings</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;
