// src/components/AboutUs.js
import React from 'react';
import './AboutUs.css';
import { Link } from 'react-router-dom'; // Import Link

const AboutUs = () => { 
  return (
    <section className="about-us-section">
      <div className="about-us-container">
        <div className="left-content slide-in-left">
          <h1>Our Story</h1>
          <div className="underline"></div>
          <Link to="/about" className="read-more">Read More</Link>
        </div>
        <div className="right-content">
          <h2>Get to Know Us</h2>
          <p>
          At Target BluPrint, we specialize in creating simple, 
          effective websites designed specifically for small businesses. As a new company, our mission is to 
          help entrepreneurs and small business owners establish their online presence quickly and affordably. We focus 
          on building clean, user-friendly websites that reflect your brand’s identity and connect you with your audience. 
          Our websites are crafted to help you kick-start your business with a straightforward, 
          fast, and cost-effective approach. Let us be a part of your journey as you grow your business online.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
