// src/components/OurWorks.js
import React from 'react';
import './OurWorks.css';
import { Link } from 'react-router-dom';

const OurWorks = () => {
  return (
    <section className="our-works">
      <div className="background-blue"></div>
      <div className="background-orange background-orange-left"></div>
      <div className="background-orange background-orange-top-right"></div>
      <div className="image-container">
        <img src="ourworksimg.jpg" alt="Our Works Background" className="work-image" />
      </div>
      <div className="text-box">
        <h2>Tools & Technologies</h2>
        <p>
        We build with ReactJS for dynamic interfaces, style with CSS for a polished look, 
        and use EmailJS to seamlessly integrate email services
        </p>
      {/*  <Link to="/learn-more" className="learn-more">Learn more</Link>*/}
      </div>
    </section>
  );
};

export default OurWorks;
