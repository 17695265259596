import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-item">
          <h3>EMAIL</h3>
          <p>targetbluprint@gmail.com</p>
        </div>
        <div className="footer-item">
          <h3>PHONE</h3>
          <p>0450191976</p>
        </div>
        <div className="footer-item">
          <h3>FOLLOW</h3>
          <div className="social-icons">
            <a 
              href="https://www.facebook.com/profile.php?id=61567788455187" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a 
              href="https://www.linkedin.com/in/target-bluprint-09310a336/" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a 
              href="https://www.instagram.com/targetbluprint/" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

