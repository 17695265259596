// src/components/LatestPosts.js
import React from 'react';
import './LatestPosts.css';

const LatestPosts = () => {
  return (
    <div className="latest-posts-section slide-in-bottom">
      <h2>Our Projects</h2>
      <div className="posts-grid">
        <a 
          href="https://39steps.homes/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="post-card-link"
        >
          <div className="post-card">
            <img src="/39STEPSHOMEPAGE.png" alt="Post 1" />
            <div className="post-content">
              <h3>39 Steps</h3>
              <p>
                39 Steps is a professional home building website offering custom home construction, 
                renovations, and extensions, designed to help clients bring their dream homes to life.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default LatestPosts;

