import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './Components/Homepage';
import Navbar from './Components/Navbar';
import Contact from './Components/Contact'; // Import the Contact component
import Footer from './Components/Footer';
import ScheduleCall from './Components/ScheduleCall';
import About from './Components/About';
import ScrollToTop from './Components/ScrollToTop';


function App() {
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<Contact />} /> {/* Add Contact Route */}
        <Route path="/schedulecall" element={<ScheduleCall />} /> 
        <Route path="/about" element={<About />} /> {/* About page route */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;


