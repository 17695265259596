// src/components/IntegralProcess.js
import React from 'react';
import './IntegralProcess.css';
import { Link } from 'react-router-dom';

const IntegralProcess = () => {
  return (
    <div className="integral-process-section">
      <div className="process-container">
        <div className="steps-side slide-in-left">
          <div className="step-card">
            <h3>Strategy Call</h3>
            <p>We'll have a detailed meeting to tailor our winning strategy with your brand.</p>
            <span className="step-number">Step 1</span>
          </div>
          <div className="step-card">
            <h3>Onboarding</h3>
            <p>Smoothly integrate into our system where we set up everything needed before launching.</p>
            <span className="step-number">Step 2</span>
          </div>
          <div className="step-card">
            <h3>Launch</h3>
            <p>Launch the website within 21 days of our strategy call.</p>
            <span className="step-number">Step 3</span>
          </div>
        </div>
        <div className="content-side slide-in-bottom">
          <h2 className="process-title">The <span>Integral</span> Process</h2>
          <p>
            From initial contact to final launch, our approach is designed for speed and results. Here's how we work with you every step of the way.
          </p>
          <Link to="/contact">
        <button className="cta-button">Schedule a Call</button>
      </Link>
        </div>
      </div>
    </div>
  );
};

export default IntegralProcess;
