// src/components/Homepage.js
import React, { useEffect } from 'react';
import './Homepage.css';
import AboutUs from './AboutUs';
import Services from './Services';
import LatestPosts from './LatestPosts';
import IntegralProcess from './IntegralProcess';
import OurWorks from './OurWorks';
import { Link } from 'react-router-dom';

const Homepage = () => {
  useEffect(() => {
    const elementsLeft = document.querySelectorAll('.slide-in-left');
    const elementsBottom = document.querySelectorAll('.slide-in-bottom');
  
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show'); // Add the 'show' class when in view
          observer.unobserve(entry.target); // Stop observing the element after animation
        }
      });
    });
  
    elementsLeft.forEach((el) => observer.observe(el)); // Observe elements with 'slide-in-left'
    elementsBottom.forEach((el) => observer.observe(el)); // Observe elements with 'slide-in-bottom'
  
    // Cleanup the observer when component unmounts
    return () => {
      elementsLeft.forEach((el) => observer.unobserve(el));
      elementsBottom.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <div className="homepage">
      <header className="homepage-header"></header>

      <div className="homepage-content">
        <div className="welcome-text">
      <h1>
        Welcome<br />
      <span className="small-text">To Target </span><span className="highlight-text">BluPrint</span>
      </h1>

        </div>
        <div className="image-section">
         <img src="homepageimage.jpg" alt="Building Your Online Presence" />
         <div className="hover-text-box">We Create Websites For The Perfect Experience</div>
         <p>Building Your Online Presence</p>
      </div>

      </div>

      {/* Explore Button with Animated Arrows */}
      <div className="explore">
      <Link to="/about">
        <button className="explore-button">
          Explore
            <span className="arrow">
              <i className="fas fa-caret-down"></i>
            </span>
        </button>
        </Link>
      </div>

      <AboutUs />
      <OurWorks />
      <Services />
      <IntegralProcess />
      <LatestPosts />
    </div>
  );
};

export default Homepage;
