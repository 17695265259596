import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_mimexpr',       // Replace with your EmailJS Service ID
      'template_datbios',      // Replace with your EmailJS Template ID
      form.current,
      'ggIjjnHEyN_oahOOL'           // Replace with your EmailJS User ID
    )
    .then((result) => {
      console.log(result.text);
      setMessage('Thanks for submitting!');
    }, (error) => {
      console.log(error.text);
      setMessage('Something went wrong. Please try again.');
    });

    e.target.reset();
  };

  return (
    <div className="contact-page">
      <h1>Contact</h1>
      <div className="contact-info">
        <p><i className="fas fa-phone"></i> 0450191976</p>
        <p><i className="fas fa-envelope"></i> targetbluprint@gmail.com</p>
      </div>

      {/* Enquiry Text */}
      <p className="enquiry-text">Send an enquiry to book a call</p>

      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="form-group">
          <input type="text" name="first_name" placeholder="First Name" required />
          <input type="text" name="last_name" placeholder="Last Name" required />
        </div>
        <div className="form-group">
          <input type="email" name="email" placeholder="Email *" required />
        </div>
        <div className="form-group">
          <textarea name="message" placeholder="Message" required></textarea>
        </div>
        <button type="submit" className="submit-button">Send</button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
}

export default Contact;
