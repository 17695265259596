import React from 'react';
import { InlineWidget } from 'react-calendly'; // Calendly's React component
import './ScheduleCall.css'; // Create this CSS file for styling

const ScheduleCall = () => {
  return (
    <div className="schedule-call-page">
      <h2>Schedule a Call with Us</h2>
      <p>Select a time that works for you directly from the calendar below:</p>
      <div className="calendly-widget">
        <InlineWidget url="https://calendly.com/targetbluprint/30min" />
      </div>
    </div>
  );
};

export default ScheduleCall;
